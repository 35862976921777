import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Aplikacja kolagenu'}>
      <Helmet title={"Aplikacja kolagenu | Lekarz Medycyny Estetycznej | Świdnica"} />
    </OfferBase>
  )
}

export default Offer
